import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { AdminLoginService } from '../services/admin-login.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AdminAuthInterceptor implements HttpInterceptor {

  constructor(private adminLoginService: AdminLoginService, private router: Router, private toastr: ToastrService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const adminToken = this.adminLoginService.getAdminToken();
    let store_id = JSON.parse(localStorage.getItem('selected_store'))?.id || 3;
    store_id = store_id.toString();
    let route = this.router.url.split('/')[1];
    if (adminToken && route === 'admin') {
      let headers = new HttpHeaders({
        'Authorization': adminToken,
        'Content-Type': 'application/json',
        'store_id': store_id 
      });

      const clone = request.clone({ headers });
      return next.handle(clone).pipe(
        catchError((error: any) => {
          if (error.status === 401) {
            this.adminLoginService.logout();
            this.toastr.error('Your session has expired. Please log in again.', 'Token Expired');
          }
          return throwError(() => error);
        })
      );
    }
  
    return next.handle(request);
  }

}

