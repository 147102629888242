import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormate'
})
export class DateFormatePipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    if(!value) { return ''};
    let date = value.split('-');
    let newDate = `${date[2]}-${date[1]}-${date[0]}`;
    return newDate;
  }
}
