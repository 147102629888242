import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent {
  @Input() userType;
  @Input() code: string;

  constructor(private toastr: ToastrService) {}

  copyCode() {
    navigator.clipboard.writeText(this.code).then(() => {
      this.toastr.success('Code copied to clipboard!');
    }).catch(err => {
      this.toastr.error('Failed to copy code: ' + err);
    });
  }
}