<div class="container-fluid p-0">
    <div class="row">
      <div class="col-12">
        <div class="login-card">
          <div>
            <div class="login-main">
              <form class="theme-form" [formGroup]="adminLoginForm">
                <h4 class="text-center">Admin Sign in</h4>
                <div class="form-group">
                  <label class="col-form-label" for="name">User Name</label>
                  <input class="form-control" id="name" type="text" [ngClass]="{'border-danger': isError('user_name')}" placeholder="Username" formControlName="user_name" />
                  <div class="text text-danger mt-1" *ngIf="isError('user_name') && adminLoginForm.get('user_name').hasError('required')">User Name is required</div>
                </div>
                <div class="form-group mb-4">
                  <label class="col-form-label" for="password">Password</label>
                  <input class="form-control" id="password" [type]="show ? 'text' : 'password'" formControlName="password" [ngClass]="{'border-danger': isError('password')}" placeholder="Password" />
                  <div class="eyes-icon">
                    <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                    <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                  </div>
                  <div class="text text-danger mt-1" *ngIf="isError('password')">Password is required</div>
                </div>
                <div class="form-group mb-0">
                  <div class="text-end mt-3">
                    <button class="btn btn-primary d-block w-100" [disabled]="!adminLoginForm.valid" (click)="login()" type="button"><span>Sign in</span></button>
                  </div>
                </div>
              </form>
              <!-- social login -->
              <!-- <div class="mt-2">
                <asl-google-signin-button
                type='standard'
                size='large'
                width="370"
                theme='outline'
                logo_alignment="center"
              ></asl-google-signin-button>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>