import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiAdminService {

  baseUrl = environment.apiUrl;
  private apiUrl = `${this.baseUrl}api/admin/auth/`;

  constructor(private http: HttpClient, private router: Router) {}
 
  login(data: any) {
    return this.http.post(`${this.apiUrl}login`, data);
  }

  socialLogin(data: any) {
    return this.http.post(`${this.apiUrl}social-login`, data);
  }

  //Organization Api's
  getOrganizationList(data: any) {
    return this.http.post(`${this.apiUrl}organization/list`,data);
  }

  updateOrganization(organizationId: number, data: any) {
    return this.http.put(`${this.apiUrl}organization/${organizationId}`, data);
  }
  
  createOrganization (data: any) {
    return this.http.post(`${this.apiUrl}organization`, data);
  }

  deleteOrganization(organizationId: number){
    return this.http.delete(`${this.apiUrl}organization/${organizationId}`);
  }

  blockUnblockOrganization(organizationId: number , data:any){
    return this.http.post(`${this.apiUrl}organization/block-unblock/${organizationId}`, data);
  }
  
  //Dashboard
  getAdminDashboardList() {
    return this.http.get(`${this.apiUrl}dashboard`);
  }

  changePassword(data: any) {
    return this.http.post(`${this.apiUrl}change-password`, data);
  }
  
  updateProfile(data: any) {
    return this.http.post(`${this.apiUrl}update-profile`, data);
  }

  getAdminProfile() {
    return this.http.get(`${this.apiUrl}profile`)
  }

}
