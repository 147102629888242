import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AdminLoginService } from '../../services/admin-login.service';
import { LoginService } from '../../services/login.service';
import { OrganizationLoginService } from '../../services/organization-login.service';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent {
  
  public adminLoginForm: FormGroup;
  public show: boolean = false;
  isLoggedIn: boolean = false;

  constructor(private fb: FormBuilder, private router: Router, private adminLoginService: AdminLoginService, private loginService: LoginService, private organizationLoginService: OrganizationLoginService) {
    this.adminLoginForm = this.fb.group({
      user_name: ["", Validators.required],
      password: ["", Validators.required]
    });
  }

  ngOnInit() {
    localStorage.removeItem('token');
    localStorage.removeItem('pages');
    localStorage.removeItem('staffName');
    localStorage.removeItem('staffRole');
    localStorage.removeItem('stores');
    localStorage.removeItem('selected_store');
    localStorage.removeItem('page_id');
    localStorage.removeItem('staff_id');
    localStorage.removeItem('is_show');
    localStorage.removeItem('is_washing_added');
    localStorage.removeItem('expired_at');   
    localStorage.removeItem('organizationToken');
    localStorage.removeItem('orgName');
    localStorage.removeItem('orgRole');
    localStorage.removeItem('expired_at');
    localStorage.removeItem('code');
    if(this.adminLoginService.isAdminLoggedIn()) {
      this.router.navigate(['/admin/dashboard']);
    }
    // this.adminLoginService.googleLogin();
  }

  login() {
    if (this.adminLoginForm.valid) {
      const userName = this.adminLoginForm.value['user_name'];
      const password = this.adminLoginForm.value['password'];
      this.adminLoginService.login(userName, password);
    }
  }

  isError(control: string) {
    const data = this.adminLoginForm.get(control);
    return data && (data.touched || data.dirty) && data.invalid;
  }  

  showPassword(){
    this.show = !this.show
  }

}
