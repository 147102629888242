import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-error400',
  templateUrl: './error400.component.html',
  styleUrls: ['./error400.component.scss']
})
export class Error400Component implements OnInit {

  constructor(private loginService: LoginService) { }

  ngOnInit(): void {
  }

  getRouterLink(): string {
    return this.loginService.isLoggedIn() ? '/store/dashboard' : '/login';
  }

}
