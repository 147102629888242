import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
import { LoaderService } from 'src/app/shared/services/loader.service';

@Component({
  selector: 'app-washing-product-details',
  templateUrl: './washing-product-details.component.html',
  styleUrls: ['./washing-product-details.component.scss']
})
export class WashingProductDetailsComponent implements OnInit {
  washingProductForm: FormGroup;
  @Input() tableData: any;
  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private toastr: ToastrService,
    private loader: LoaderService,
    private activeModal: NgbActiveModal
  ) {
    this.washingProductForm = this.fb.group({
      product_name: [''],
      washermen_name: [''],
    });
  }

  ngOnInit() {
    this.fetchWashingProductDetails();
  }

  fetchWashingProductDetails() {
    this.washingProductForm.patchValue({
      product_name: this.tableData.productName,
      washermen_name: this.tableData.washermanName,
    });
  }

  closeModal() {
    this.activeModal.close();
  }

  submitForm() {
    if (this.washingProductForm.valid) {
      const data = {
        product_id: Number(this.tableData.product_id),
        washermen_id: Number(this.tableData.washermen_id)
      };
      
      this.loader.showLoader();
      this.apiService.updateProductInWash(this.tableData.washProductId, data).subscribe({
        next: (response: any) => {
          this.toastr.success(response.message);
          this.activeModal.close('submit');
          this.loader.hideLoader();
        },
        error: (error: any) => {
          this.toastr.error('Error updating washing product');
          this.loader.hideLoader();
        }
      });
    } else {
      this.washingProductForm.markAllAsTouched();
    }
  }

  isRequiredError(controlName: string) {
    const control = this.washingProductForm.get(controlName);
    return control && control.hasError('required') && control.touched;
  }
  onKeydown(event:KeyboardEvent){
      if(event.key){
        this.submitForm();
      }
  }
}
