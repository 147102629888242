<div class="full-screen-modal">
  <div class="modal-header">
    <h5 class="modal-title" id="subscriptionModalLabel">Subscription Expired</h5>
  </div>
  <div class="modal-body">
    <img src="../../../../assets/images/subscription-bg.png" alt="Img Not Found" style="width: 60%;">
    <p class="m-0">Your subscription has expired. Please refresh to check your subscription status.</p>
  </div>
  <div class="modal-footer justify-content-center">
    <button type="button" class="btn btn-primary" (click)="refresh()" aria-label="Refresh Subscription Status">Refresh</button>
  </div>
</div>