import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appMobileValidator]'
})
export class MobileValidatorDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) 
  onInput(event: Event) {
    let input = this.el.nativeElement.value;
    let newInput = input.replace(/\D/g, '');
    this.el.nativeElement.value = newInput;
  }
}