import { Component, OnInit, Inject, HostListener, ElementRef, ViewChild } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { NavService } from "../../services/nav.service";
import { LayoutService } from "../../services/layout.service";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Router } from "@angular/router";
import { LoaderService } from "../../services/loader.service";

SwiperCore.use([Navigation, Pagination, Autoplay]);
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  public elem: any;
  selectedVal: string;
  isDropdownOpen: boolean;
  selected_store: any;
  data: any;
  filterDropdownData: any;
  headerDropdownData: any;
  role: any;
  @ViewChild('dropDown') dropDown: ElementRef;
  constructor(public layout: LayoutService, public navServices: NavService, @Inject(DOCUMENT) private document: any, private router: Router, private loader: LoaderService) {}

  ngOnInit() {
    this.elem = document.documentElement;
    setTimeout(()=>{
      this.data =  JSON.parse(localStorage.getItem('stores'));
      this.selected_store = JSON.parse(localStorage.getItem('selected_store'));
      if(this.selected_store === null && this.data) {
        this.selectedVal = this.data[0]?.store_name;
        localStorage.setItem('selected_store', this.data[0]?.store_name);
      } else {
        this.selectedVal = this.selected_store?.store_name;
      }
      if(this.data) {
        this.headerDropdownData = this.data;
        this.filterDropdownData = this.headerDropdownData;
      }
    },500)

    // check headerselected_store
    this.role = this.router.url.split('/')[1];
  }
  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
    this.navServices.megaMenu = false;
    this.navServices.levelMenu = false;
  }

  layoutToggle() {
    if ((this.layout.config.settings.layout_version = "dark-only")) {
      document.body.classList.toggle("dark-only");
    }
    document.body.remove;
  }

  languageToggle() {
    this.navServices.language = !this.navServices.language;
  }

  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen;
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  onFilterBlur() {
    const selected = JSON.parse(localStorage.getItem('selected_store'))?.store_name;
    if(this.selectedVal === '' && selected) {
       this.selectedVal = selected;
    }
  }

  filterData() {
    this.isDropdownOpen = true;
    if (this.selectedVal) {
      this.filterDropdownData = this.headerDropdownData.filter(
        (item: any) => item?.store_name.toLowerCase().includes(this.selectedVal.trim().toLowerCase())
      );
      if(this.filterDropdownData.length == 0){
        this.isDropdownOpen = false;
      }else{
        this.isDropdownOpen = true;
      }
    } else {
      this.filterDropdownData = this.headerDropdownData.slice();
    }
  }

  selectOption(item: any) {
    this.selectedVal = item?.store_name;
    let store = JSON.stringify(item);
    localStorage.setItem('selected_store', store);
    this.isDropdownOpen = false;
    let currentUrl = this.router.url.split('/');
    if(currentUrl[1] === 'store' && currentUrl[2] === 'dashboard') {
      window.location.reload();
    } else {
      this.router.navigate(['store/dashboard']);
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    if (!this.dropDown?.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
    }
  }

}
