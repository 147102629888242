<div>
    <div class="modal-header">
      <h4 class="modal-title">Create Washing</h4>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="washingForm" (keydown)="onKeyDown($event)">
        <div class="input-container" style="position: relative;">
          <label for="washermen_id">Washermen Name: <span class="text-danger">*</span></label>
          <div class="dropdown-select">
            <select id="washermen_id" formControlName="washermen_id" [class.border-danger]="isRequiredError(washingForm, 'washermen_id')">
              <option [value]="null" [selected]="true" disabled>Select Washerman</option>
              <option *ngFor="let washerman of washermenList" [value]="washerman.id">{{ washerman.name }}</option>
            </select>
            <span class="text-danger" *ngIf="isRequiredError(washingForm, 'washermen_id')">Please select a staff name</span>
          </div>
        </div>
  
        <div class="input-container">
          <label for="return_date">Select Return Date: <span class="text-danger">*</span></label>
          <div>
            <input type="date" id="return_date" formControlName="return_date" [class.border-danger]="isRequiredError(washingForm, 'return_date')" />
            <span class="text-danger" *ngIf="isRequiredError(washingForm, 'return_date')">Please select a return date</span>
          </div>
        </div>
  
        <div class="input-container">
          <label for="amount">Amount: <span class="text-danger">*</span></label>
          <div>
            <input type="number" id="amount" formControlName="amount" [class.border-danger]="isRequiredError(washingForm, 'amount')" />
            <span class="text-danger" *ngIf="isRequiredError(washingForm, 'amount')">Please enter an amount</span>
          </div>
        </div>
  
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="onCreateWashing()">Create</button>
      <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
    </div>
  </div>
  