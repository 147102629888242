import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionComponent } from '../shared/components/subscription/subscription.component';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  constructor(private modalService: NgbModal) {}
  pdfUrl: any;
  // isWhatsappClicked: boolean = false;

  showSubscriptionExpiredModal() {
    const modalRef: NgbModalRef = this.modalService.open(SubscriptionComponent, {
      centered: true,
      keyboard: false,      
      backdrop: 'static',   
      windowClass: 'subscription-modal'
    });
  }

  setPdfBlobUrl(url: any) {
    this.pdfUrl = url;
  }

  getPdfBlobUrl() {
    return this.pdfUrl;
  }

  // click(isWhatsapp: boolean) {
  //   this.isWhatsappClicked = isWhatsapp;
  // }

  // isWhatsappClickedStatus() {
  //   return this.isWhatsappClicked;
  // }
}