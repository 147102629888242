<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12 tablet-scn">
      <div class="card">
        <div class="card-header f-light" [style.padding]="headerPadding">
          <div class="d-flex justify-content-between align-items-center tablet-scn-header">
            <h5>{{ tableTitle }}</h5>
          
            <div class="d-flex gap-2 search-feild">
              <div class="input-group search-menu" style="width: 300px;" tabindex="1" *ngIf="isSearch" (focusout)="onInputBlur()">
                <input type="text" class="form-control" placeholder="Search" [(ngModel)]="searchText" (keyup)="onKeyUp($event, 'common')" (focus)="onInputFocus()">
                <button class="btn search-button" (mousedown)="$event.preventDefault();" [ngClass]="{'enable': searchText || isEnable}" [disabled]="!isEnable" type="button" (click)="onSearch($event)">
                  <i class="fa fa-search"></i>
                </button>
              </div>
  
              <button class="btn btn-primary" type="button" (click)="onButtonClick()" *ngIf="istableBtnShow && canCreate(page_id)">{{tableButton}}</button>
            </div>
          </div>
          <div *ngIf="tableType === 'rent_order'" tabindex="1" (focusout)="onInputBlur()" class="d-flex gap-4 pt-3">
            <div class="input-group search-menu" style="width: 300px;">
              <input type="text" class="form-control" placeholder="search order id" [(ngModel)]="orderId" (keyup)="onKeyUp($event, 'order')" (focus)="onInputFocus()">
            </div>
            <div class="input-group search-menu" style="width: 300px;">
              <input type="date" class="form-control" title="search book date" [(ngModel)]="bookDate" (ngModelChange)="onDateChange($event, 'bookDate')" (focus)="onInputFocus()">
            </div>
            <div class="input-group search-menu" style="width: 300px;">
              <input type="date" class="form-control" placeholder="search return date" [(ngModel)]="returnDate" (ngModelChange)="onDateChange($event, 'returnDate')" (focus)="onInputFocus()">
            </div>
            <div class="search-main">
              <button class="btn search-button" (mousedown)="$event.preventDefault();" [ngClass]="{'enable': (orderId || bookDate || returnDate || isEnable)}" [disabled]="!isEnable"  type="button" (click)="onRentSearch()">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      
        <div class="card-body pt-3 custom-datatable noscroll" >
          <div class="custom-datatable table-responsive" >
            <div class="table-scrollable-x">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">S.No.</th> 
                    <th *ngFor="let header of tableHeaders" scope="col">{{  header.label }}</th>
                    <th *ngIf="tableTitle === 'Return Products List' && ([4].includes(page_id) || canAction(page_id)); else noAction">Actions</th>
                    <th *ngIf="tableTitle === 'Available'">Product Image</th>
                    <th *ngIf="tableTitle === 'Organization List'|| tableTitle === 'Staff List'">Block/Unblock</th>
                    <th *ngIf="tableTitle === 'Store List'">Store Image</th>
                    <th [ngClass]="{'text-center': tableTitle === 'Lead List' || tableTitle === 'Rent Order List'}"  *ngIf="showActions && ([15,2,6,7].includes(page_id) || canAction(page_id)); else noAction">Actions</th>
                    <ng-template #noAction>
                      <th *ngIf="(!showActions || !([15,2,6,7].includes(page_id) || canAction(page_id)))&&page_id !== 16"></th>
                    </ng-template>
                  </tr>
                </thead> 
                <tbody >
                  <tr *ngFor="let item of tableItems let i = index">
                    <td [style.width.%]="'8'">{{ (currentPage - 1) * pageSize + i + 1 }}</td>
                    <ng-container  *ngFor="let header of tableHeaders">
                      <td *ngIf="header.key !== 'expired_at' && header.key !== 'dob' && header.key !== 'function_date' && header.key !== 'book_date' && header.key !== 'return_date' && header.key !== 'date' && header.key !== 'returnDate'" [style.width.%]="header.width">{{ item[header.key] }}</td>
                      <td *ngIf="header.key === 'expired_at' || header.key === 'dob' || header.key === 'function_date' || header.key === 'book_date' || header.key === 'return_date' || header.key === 'date' || header.key === 'returnDate'" id="demo099" [ngClass]="{'red-color': item.is_expire }" [style.width.%]="header.width">{{ item[header.key] | dateFormate}}</td>
                    </ng-container>
                    <td *ngIf="tableTitle === 'Return Products List'">
                      <button class="btn btn-primary" (click)="washForm(item)">Move to Washing</button>
                    </td>
                    <td *ngIf="tableTitle === 'Available'">
                      <img *ngIf="item.product_image" [src]="baseUrl + item.product_image" alt="No Image" width="100" height="100" (click)="openImageModal(baseUrl + item.product_image)" class="clickable-image">
                      <span *ngIf="!item.product_image">No Image Available</span>
                    </td>        
                    <td *ngIf="tableTitle === 'Store List'">
                      <img *ngIf="item.image" [src]="baseUrl + item.image" alt="No Image" width="100" height="100">
                      <span *ngIf="!item.image">No Image Available</span>
                    </td> 
                    <td *ngIf="tableTitle === 'Organization List'|| tableTitle === 'Staff List'">
                      <button class="btn" [ngClass]="{'btn-danger': item?.is_active, 'btn-success': !item?.is_active}" (click)="onStatusChange(item)">{{ item?.is_active ? 'Block' : 'Unblock' }}</button>
                    </td> 
                    <td *ngIf="showActions">
                      <div class="action" [ngClass]="{'justify-content-center': tableTitle === 'Lead List'}">
                        <ul [ngClass]="{'align-items-center': tableTitle === 'Rent Order List'}">
                          <!-- hidden width maintain start -->
                          <li  style="width: 28px;" class="edit" *ngIf="(tableTitle === 'Rent Order List' && item.status === 'RETURNED')"></li>
                          <!-- hidden width maintain end -->
                          <li class="edit" *ngIf="tableTitle !== 'Washing List' && !(tableTitle === 'Rent Order List' && item.status === 'RETURNED') && canEdit(page_id)">
                            <a [title]="'Edit'"><i class="icon-pencil-alt" (click)="onEditItem(item)"></i></a>
                          </li>
                          <li class="delete" (click)="onDeleteItem(item)" *ngIf="tableTitle !== 'Washing List' && canDelete(page_id)">
                            <a [title]="'Delete'"><i class="icon-trash" ></i></a>
                          </li>
                          <li class="return return-product-hidden" *ngIf="(tableTitle === 'Rent Order List' && item.status === 'RETURNED' && showActions)"></li>
                          <li class="return return-product" (click)="returnItem(item)" *ngIf="showActions && tableTitle === 'Rent Order List'">
                            <a [title]="'Return'" class="hand-icon" *ngIf="item.status !== 'RETURNED'">
                              <svg class="icon return-product" width="30" height="18" viewBox="0 0 28 30" xmlns="http://www.w3.org/2000/svg">
                                <use xlink:href="assets/svg/icon-sprite.svg#return-box"></use>
                              </svg>
                            </a>
                          </li>                                                                   
                          <li class="return" (click)="returnProduct(item)" *ngIf="showActions && tableTitle === 'Buy Back Product List'">
                            <a [title]="'Return'" class="hand-icon">
                              <svg class="icon return-product" width="30" height="18" viewBox="0 0 28 30" xmlns="http://www.w3.org/2000/svg" style="margin-right: 12px;">
                                <use xlink:href="assets/svg/icon-sprite.svg#return-box"></use>
                              </svg>
                            </a>
                          </li>                                                         
                          <li class="view text-center" (click)="OnViewItem(item)" *ngIf="tableTitle === 'Rent Order List'">
                            <a [title]="'View'"><i class="icon-eye"></i></a>
                          </li>
                          <li class="view text-center" (click)="OnPrintItem(item, 'printItem')" *ngIf="tableTitle === 'Rent Order List' ">
                            <a [title]="'View'"><i class="icon-printer"></i></a>
                          </li> 
                          <li *ngIf="tableTitle === 'Washing List'"><button type="button" class="btn btn-primary" (click)="returnList(item)">Return</button></li>
                          <li *ngIf="tableTitle === 'Lead List'">
                            <a [title]="'Follow Up'"><i class="icon-user" (click)="followUp(item)"></i></a>
                            <a [title]="'View'"><i class="icon-eye" (click)="viewHistory(item)"></i></a>
                          </li>
                        </ul>
                      </div>
                    </td>
                    <td *ngIf="(!showActions || !([15,2,6,7].includes(page_id) || canAction(page_id)))&& page_id !== 16"></td>      
                    <th *ngIf="page_id===5"></th> 
                  </tr>
                  <tr *ngIf="tableItems.length <= 0">
                     <td colspan="100%" class="noFound">No data Found</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="d-flex justify-content-between mt-3" *ngIf="isPagination">
              <select class="form-select" style="width: auto" [(ngModel)]="pageSize" name="pageSize" (change)="onPageSizeChange($event.target.value)">
                <option *ngFor="let option of pageSizeOptions" [value]="option">{{ option }} items per page</option>
              </select>
              <ngb-pagination
                [collectionSize]="totalCount"
                [(page)]="currentPage"
                [pageSize]="pageSize"
                (pageChange)="onPageChange($event)"
                class="ms-3"
              ></ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-image-modal [imageUrl]="selectedImage" [isVisible]="isModalVisible"(onClose)="closeImageModal()"></app-image-modal>       