import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss']
})
export class ImageModalComponent {
  @Input() imageUrl: string = '';
  @Input() isVisible: boolean = false;
  @Output() onClose = new EventEmitter<any>();

  close() {
    this.onClose.emit();
  }
}
