import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LoaderService } from '../shared/services/loader.service';
import { ApiService } from './api.service';
import { ToastrService } from 'ngx-toastr';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { ApiOrganizationService } from './api-organization.service';
import { OrganizationLoginService } from './organization-login.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  isLoggedIn$ = this.isAuthenticatedSubject.asObservable();
  selectedRole$ =  new BehaviorSubject<string>('Staff');
  authStateSubscription: Subscription;
  roleType: string;
  googleToken:any;
  constructor(private router: Router, private loaderService : LoaderService, private apiService: ApiService, private toastr: ToastrService, private authService: SocialAuthService, private apiOrganizationService: ApiOrganizationService, private orgLoginService: OrganizationLoginService) {}
  login(username: string, password: string, code: string): void {
    let data = {
      user_name: username,
      password: password,
      code: code
    }
    this.loaderService.showLoader();
    this.apiService.login(data).subscribe({
      next: (res: any) => {
        localStorage.setItem('token', res.data.accessToken);
        localStorage.setItem('pages', JSON.stringify(res.data.page));
        localStorage.setItem('staffName', JSON.stringify(res.data?.name));
        localStorage.setItem('staffRole', JSON.stringify(res.data?.role));
        localStorage.setItem('stores', JSON.stringify(res.data?.stores));
        localStorage.setItem('staff_id', JSON.stringify(res.data?.staff_id));
        localStorage.setItem('selected_store', JSON.stringify(res.data?.stores[0]));
        localStorage.setItem('expired_at', JSON.stringify(res.data?.stores[0]?.organizations.expired_at));
        localStorage.setItem('is_washing_added', JSON.stringify(res.data?.stores[0]?.organizations.organization_details?.is_washing_added));
        this.router.navigate(['/store/dashboard']);
        this.toastr.success(res.message);
        this.loaderService.hideLoader();
      }, error: (err: any) => {
        this.toastr.error('Please enter valid credentials');
        this.loaderService.hideLoader();
      }
    });
  }

  googleLogin(code: any) {
    if (this.authStateSubscription) {
      this.authStateSubscription.unsubscribe();
    }    
    this.selectedRole$.subscribe((role:any) => {
       this.roleType = role;
    })
    this.authStateSubscription = this.authService.authState.subscribe((user) => {
      if (user && user.idToken !== this.googleToken) { 
        this.googleToken = user?.idToken; 
        let requestBody = {
          id_token: this.googleToken,
          code: code
        };
        if(this.roleType === 'Staff') {
          this.staffSocialLogin(requestBody);
        }
      }
    })
  }

  staffSocialLogin(data: any) {
    this.loaderService.showLoader();
    let requestBody = data;
    this.apiService.socialLogin(requestBody).subscribe({
      next: (res: any) => {
        localStorage.setItem('token', res.data.accessToken);
        localStorage.setItem('staffName', JSON.stringify(res.data?.name));
        localStorage.setItem('staffRole', JSON.stringify(res.data?.role));
        localStorage.setItem('stores', JSON.stringify(res.data?.stores));
        localStorage.setItem('selected_store', JSON.stringify(res.data?.stores[0]));
        localStorage.setItem('staff_id', JSON.stringify(res.data?.staff_id));
        localStorage.setItem('pages', JSON.stringify(res.data.page));
        localStorage.setItem('expired_at', JSON.stringify(res.data?.stores[0]?.organizations.expired_at));
        localStorage.setItem('is_washing_added', JSON.stringify(res.data?.stores[0]?.organizations.organization_details?.is_washing_added));
        this.router.navigate(['/store/dashboard']);
        this.toastr.success(res.message);
        this.loaderService.hideLoader();
      }, error: (err: any) => {
        if (err.status === 404) {
          this.toastr.error('Store not found. Please check your credentials.');
        } else {
          this.toastr.error('Store login failed. Please try again later.');
        }
        this.loaderService.hideLoader();
      }
    });
  }

  logout(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('pages');
    localStorage.removeItem('staffName');
    localStorage.removeItem('staffRole');
    localStorage.removeItem('stores');
    localStorage.removeItem('selected_store');
    localStorage.removeItem('page_id');
    localStorage.removeItem('staff_id');
    localStorage.removeItem('is_show');
    localStorage.removeItem('is_washing_added');
    localStorage.removeItem('expired_at');   
    this.router.navigate(['/login']);
    if (this.authStateSubscription) {
      this.authStateSubscription.unsubscribe();
    }
    this.authService.signOut().then(() => {
      this.googleToken = null;
    })
    .catch((error) => {
      console.error('Error during sign out:', error);
    });
  }

  getToken(): string | null {
    return localStorage.getItem('token');
  }

  isLoggedIn() {
    return this.getToken() !== null;
  }
}
