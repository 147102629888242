import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
import { LoaderService } from 'src/app/shared/services/loader.service';

@Component({
  selector: 'app-return-product-popup',
  templateUrl: './return-product-popup.component.html',
  styleUrls: ['./return-product-popup.component.scss']
})
export class ReturnProductPopupComponent {
 @Input() productId: any;
  productDetails: any;
  productForm!: FormGroup;
  is_defected: boolean = false;

 constructor(private loader: LoaderService,private fb: FormBuilder, private apiService: ApiService, private toastr: ToastrService, private activeModal: NgbActiveModal,) {}
 ngOnInit() {
   this.getBuyBackProductById(this.productId);
   this.productForm = this.fb.group({
    payment: ['', Validators.required],
    return_amount: ['', Validators.required],
    defected_amount: ['', Validators.required],
    is_defected: ['', Validators.required],
    is_returned: [{ value: '', disabled: true }, Validators.required]
   })
 }

 getBuyBackProductById(id: any) {
  this.loader.showLoader();
  this.apiService.getBuyBackProductById(id).subscribe({
    next: (res: any) => {
      this.loader.hideLoader();
      this.productDetails = res.data;
      this.patchFormVal(this.productDetails);
    }, error: (err: any) => {
      this.toastr.error('Something went wrong');
      this.loader.hideLoader();
    }
  })
 }

 patchFormVal(data: any) {
   this.productForm.patchValue({
     payment: data.remaining_amount,
     return_amount: data.deposite_amount,
     is_returned: data.status === 'RETURNED' ? true : false
   })
 }

 returnProduct() {
  if(this.productDetails.status !== 'RETURNED') {
    let formVal = this.productForm.value;
    let requestBody = {
      "buy_back_product_id": this.productDetails.id,
      "product_name": this.productDetails.product_name,
      "is_defected": formVal.is_defected,
      "defected_amt": formVal.defected_amount,
      "payment": formVal.payment
    }
    this.loader.showLoader();
    this.apiService.returnProduct(requestBody).subscribe({
      next: (res: any) => {
         this.toastr.success(res.message);
         this.activeModal.close('submit')
         this.loader.hideLoader();
      }, error: (err: any) => {
         this.toastr.error("Something went wrong");
         this.loader.hideLoader();
      }
    })
  } else {
    this.toastr.error('This product is already return');
  }
 }

 defectChange(event: any) {
  this.is_defected = event.target.checked;
 }

 closeModal() {
  this.activeModal.close();
 }
}
