import { AfterViewInit, Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as feather from "feather-icons";
import { LayoutService } from "../../../services/layout.service";
import { NavService } from "../../../services/nav.service";
import { fadeInAnimation } from "../../../data/router-animation/router-animation";

@Component({
  selector: "app-content",
  templateUrl: "./content.component.html",
  styleUrls: ["./content.component.scss"],
  animations: [fadeInAnimation],
})
export class ContentComponent implements OnInit, AfterViewInit {
  mainStyles: any = {};

  constructor(private route: ActivatedRoute, public navServices: NavService, public layout: LayoutService) {
    this.route.queryParams.subscribe((params) => {
      this.layout.config.settings.layout = params.layout ? params.layout : this.layout.config.settings.layout;
    });
  }

  ngOnInit() {
    this.updateMainStyles(window.innerWidth);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      feather.replace();
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.updateMainStyles(event.target.innerWidth);
  }

  updateMainStyles(width: number) {
    // For screens 800px and below (iPad Mini, small devices)
    if (width <= 900) { 
      this.mainStyles = { width: '100%' };
    }
    // For screens between 801px and 1024px (iPad Air)
    else if (width <= 1024) {
      this.mainStyles = { width: 'calc(100vw - 100px)' };
    } 
    // For larger screens (iPad Pro in landscape mode and other larger screens)
    else { 
      this.mainStyles = { width: 'calc(100vw - 285px)' };
    }
  }
  

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : "";
  }

  get layoutClass() {
    return "compact-wrapper";
  }

}
