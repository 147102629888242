<div>
    <div class="modal-header">
        <h4 class="modal-title">View History</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
    </div>
      <div class="modal-body">
        <ng-container *ngIf="leadHistory && leadHistory.length > 0">
          <app-time-line [timelineItems]="leadHistory" [timeLineHeaders]="timeLineHeaders"></app-time-line>
        </ng-container>
        
        <ng-container *ngIf="!leadHistory || leadHistory.length === 0">
          <p class="text-center">No history found for this lead.</p>
        </ng-container>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
      </div>
  </div>