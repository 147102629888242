import { DatePipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "src/app/services/api.service";
import { LoaderService } from "src/app/shared/services/loader.service";

@Component({
  selector: "app-follow-up-popup",
  templateUrl: "./follow-up-popup.component.html",
  styleUrls: ["./follow-up-popup.component.scss"],
})
export class FollowUpPopupComponent {
  followUpForm!: FormGroup;
  staffArray: any = [];
  followUpItem: [] = [];
  @Input() leadId: any;

  constructor(
    private fb: FormBuilder,
    private activeModal: NgbActiveModal,
    private toastr: ToastrService,
    private loader: LoaderService,
    private apiService: ApiService,
    private datePipe: DatePipe
  ) {}
  ngOnInit() {
    this.followUpForm = this.fb.group({
      follow_type: ["message", Validators.required],
      followDateTime: [this.getDefaultDateTime(), Validators.required],
      note: [""],
    });
  }

  onDateTimeClick(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.showPicker();
  }

  isRequiredError(form: FormGroup, controlName: string) {
    const control = form.get(controlName) as FormControl;
    return control.hasError("required") && control.touched;
  }

  onCreateLead() {
    if (this.followUpForm.valid) {
      const formValue = this.followUpForm.value;
      const { follow_type, followDateTime, note } = formValue;

      const follow_date = this.formatDate(followDateTime);
      const follow_time = this.formatTime(followDateTime);
      let staff_id = JSON.parse(localStorage.getItem("staff_id"));
      const requestBody = {
        lead_id: this.leadId,
        staff_id,
        follow_type,
        follow_date,
        follow_time,
        note,
      };

      this.loader.showLoader();

      this.apiService.createFollowUp(requestBody).subscribe({
        next: (res: any) => {
          this.followUpItem = res.data;
          this.toastr.success(res.message);
          this.loader.hideLoader();
          this.activeModal.close();
        },
        error: (error: any) => {
          this.toastr.error(error.error.message);
          this.loader.hideLoader();
        },
      });
    } else {
      Object.values(this.followUpForm.controls).forEach((control) => {
        control.markAsTouched();
      });
    }
  }

  formatDate(isoDateTime: string): string {
    const date = new Date(isoDateTime);
    return this.datePipe.transform(date, "dd-MM-yyyy") || "";
  }

  formatTime(isoDateTime: string): string {
    const date = new Date(isoDateTime);
    return this.datePipe.transform(date, "hh:mm a") || "";
  }

  closeModal() {
    this.activeModal.close();
  }

  getDefaultDateTime(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const hours = String(today.getHours()).padStart(2, "0");
    const minutes = String(today.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === "Enter") {
      this.onCreateLead();
    }
  }
}
