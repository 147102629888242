import { Component, OnInit } from "@angular/core";
import { filter } from 'rxjs/operators';
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router, NavigationEnd } from "@angular/router";
import { LoginService } from "../../services/login.service";
import { OrganizationLoginService } from "../../services/organization-login.service";
const Swal = require('sweetalert2')
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public newUser = false;
  // public user: firebase.User;
  public loginForm: FormGroup;
  public show: boolean = false
  public errorMessage: any;
  isLoggedIn: boolean = false;
  selectedRole: any;
  roleArray = ['Organization', 'Staff']
  is_checked: string;
  code: any;
  constructor(private fb: FormBuilder, public router: Router, private loginService : LoginService,private organizationLoginService: OrganizationLoginService) {
    this.loginForm = this.fb.group({
      email: ["", [Validators.required]],
      password: ["", Validators.required],
      remember: [false],
      role: ["", [Validators.required]],
      code: [""]
    });
    this.loginService.selectedRole$.subscribe(role => {
      this.selectedRole = role;
      this.loginForm.get('role')?.setValue(role);
    });
    this.organizationLoginService.selectedRole$.subscribe(role => {
      this.selectedRole = role;
      this.loginForm.get('role')?.setValue(role);
    });
  }

  ngOnInit() {
  if (!sessionStorage.getItem('reloaded')) {
    sessionStorage.setItem('reloaded', 'true');
    window.location.reload();
    return; 
  }
  localStorage.removeItem('adminToken');
  localStorage.removeItem('adminName');
  localStorage.removeItem('adminRole');
  const savedRole = localStorage.getItem('selectedRole');
  this.selectedRole = savedRole ? savedRole : 'Staff';
  this.loginForm.get('role')?.setValue(this.selectedRole);
  this.loginService.selectedRole$.next(this.selectedRole);
  this.organizationLoginService.selectedRole$.next(this.selectedRole);
    if(this.selectedRole === 'Staff') {
      if(this.loginService.isLoggedIn()) {
        this.router.navigate(['store/dashboard']);
      }
    } else if(this.selectedRole === 'Organization') {
      if(this.organizationLoginService.isOrganizationLoggedIn()) {
        this.router.navigate(['organization/dashboard']);
      }
    }
    if (this.selectedRole === 'Organization') {
      this.organizationLoginService.googleLogin();
    } 
  }
  
  login() {  
    if (this.loginForm.valid) {
      let userEmail = this.loginForm.value['email'];
      let userPassword = this.loginForm.value['password'];
      let selectedRole = this.loginForm.value['role'];
      let code = this.loginForm.value['code'];
      localStorage.setItem('selectedRole', selectedRole); 
      if(selectedRole === 'Staff') {
        this.loginService.login(userEmail, userPassword, code);
      } else if(selectedRole === 'Organization') {
        this.organizationLoginService.login(userEmail, userPassword);
      }
    } 
  }

  onCodeBlur(event: any) {
    this.code = event.target.value;
    this.loginService.googleLogin(this.code); 
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.login();
    }
  }

  isError(control) {
    const data = this.loginForm.get(control);
    return data && (data.touched || data.dirty) && data.invalid;
  }

  showPassword(){
    this.show = !this.show
  }

  roleChange($event) {
    const selectedRole = $event.target.value;
    this.loginService.selectedRole$.next(selectedRole);
    this.organizationLoginService.selectedRole$.next(selectedRole);
    this.loginForm.get('role')?.setValue(selectedRole);
    localStorage.setItem('selectedRole', selectedRole); 
  }

  handleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'F2') {
      localStorage.setItem('is_show', 'true');
      this.is_checked = localStorage.getItem('is_show') || '';
    }
  }
}
