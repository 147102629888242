<div>
  <div class="modal-header">
      <h4 class="modal-title">Create Follow Up</h4>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
  </div>
    <div class="modal-body">
      <form [formGroup]="followUpForm" (keydown)="onKeyDown($event)">
        <div class="input-container">
          <div class="d-flex gap-3 align-items-center">
             <label for="follow_type">Follow Type:</label>
             <input type="radio" id="follow_type" value="message" name="follow_type" formControlName="follow_type"> Message
             <input type="radio" id="follow_type" value="call" name="follow_type" formControlName="follow_type"> Call
           </div>
        </div>
        <div class="input-container">
          <label for="followDateTime" id="followDateTime">Follow Date-Time: <span class="text-danger">*</span></label>
            <input type="datetime-local" id="followDateTime" class="picker"  formControlName="followDateTime" [class.border-danger]="isRequiredError(followUpForm, 'followDateTime')" (click)="onDateTimeClick($event)"/>
         <span class="text-danger" *ngIf="isRequiredError(followUpForm, 'followDateTime')">Date/Time is required</span>
        </div>
        <div class="input-container">
           <label for="note">Note:</label>
           <textarea id="note" formControlName="note"></textarea>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="onCreateLead()">Create</button>
      <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
    </div>
</div>