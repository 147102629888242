import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
import { LoaderService } from 'src/app/shared/services/loader.service';

@Component({
  selector: 'app-move-to-washing-popup',
  templateUrl: './move-to-washing-popup.component.html',
  styleUrls: ['./move-to-washing-popup.component.scss']
})
export class MoveToWashingPopupComponent {
  washermenList: any[] = [];
  washingForm: FormGroup;
  @Input() productId: any;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private toastr: ToastrService,
    private loader: LoaderService,
    private activeModal: NgbActiveModal
  ) {
    this.washingForm = this.fb.group({
      washermen_id: ['', Validators.required],
      return_date: [new Date().toISOString().split('T')[0], Validators.required],
      amount: ['', Validators.required], 
    });
  }

  ngOnInit() {
    this.fetchAllWasherMenList();
  }

  fetchAllWasherMenList() {
    this.loader.showLoader();
    this.apiService.getAllWasherList().subscribe({
      next: (response: any) => {
        this.washermenList = response.data || [];
        this.washingForm.controls['washermen_id'].setValue(null);
        this.loader.hideLoader();
      },
      error: (error: any) => {
        this.toastr.error(error.error.message);
        this.loader.hideLoader();
      }
    });
  }

  onCreateWashing() {
    this.washingForm.markAllAsTouched();
    if (this.washingForm.valid) {
      const selectedWashermanId = this.washingForm.value.washermen_id;
      const returnDate = this.washingForm.value.return_date;
      const amount = this.washingForm.value.amount;

      const washingData = {
        product_id: this.productId,
        washermen_id: Number(selectedWashermanId),
        return_date: returnDate,
        amount: amount
      };

      this.loader.showLoader();
      this.apiService.updateAssignToWasherman(washingData).subscribe({
        next: (response: any) => {
          this.toastr.success(response.message);
          this.loader.hideLoader();
          this.activeModal.close('submit');
        },
        error: (error: any) => {
          this.toastr.error('Failed to create washing');
          this.loader.hideLoader();
        }
      });
    } else {
      this.toastr.error('Please fill in all required fields');
    }
  }

  closeModal() {
    this.activeModal.close();
  }

  isRequiredError(form: FormGroup, controlName: string) {
    const control = form.get(controlName) as FormControl;
    return control.hasError('required') && control.touched;
  }

  onKeyDown(event:KeyboardEvent){
    if(event.key==='Enter'){
      this.onCreateWashing();
    }
  }
}
