import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiOrganizationService {

  baseUrl = environment.apiUrl;
  private apiUrl = `${this.baseUrl}api/organization/auth/`;

  constructor(private http: HttpClient, private router: Router) { }

  login(data: any) {
    return this.http.post(`${this.apiUrl}login`, data)
  }

  socialLogin(data: any) {
    return this.http.post(`${this.apiUrl}social-login`, data);
  }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
  // dashboard API's
  getDashboardDetails() {
    return this.http.get(`${this.apiUrl}dashboard`);
  }

  //Store Api's

  getStoreList() {
    return this.http.get(`${this.apiUrl}store/list`);
  }

  getAllStoreList(data: any) {
    return this.http.post(`${this.apiUrl}store/list`, data);
  }

  updateStore(storeId: number, data: any) {
    return this.http.put(`${this.apiUrl}store/${storeId}`, data);
  }

  createStore(data: any) {
    return this.http.post(`${this.apiUrl}store`, data);
  }

  deleteStore(storeId: number) {
    return this.http.delete(`${this.apiUrl}store/${storeId}`);
  }

  //create parent category Api's

  getAllCategoryList(data: any) {
    return this.http.post(`${this.apiUrl}parent-category/list`, data);
  }

  updateCategory(categoryId: number, data: any) {
    return this.http.put(`${this.apiUrl}parent-category/${categoryId}`, data);
  }

  createCategory(data: any) {
    return this.http.post(`${this.apiUrl}parent-category`, data);
  }

  deleteCategory(categoryId: number) {
    return this.http.delete(`${this.apiUrl}parent-category/${categoryId}`);
  }

  //type Api's
  getTypeList() {
    return this.http.get(`${this.apiUrl}type/list`);
  }

  getAllTypeList(data: any) {
    return this.http.post(`${this.apiUrl}type/list`, data);
  }

  updateType(typeId: number, data: any) {
    return this.http.put(`${this.apiUrl}type/${typeId}`, data);
  }

  createType(data: any) {
    return this.http.post(`${this.apiUrl}type`, data);
  }

  deleteType(storeId: number) {
    return this.http.delete(`${this.apiUrl}type/${storeId}`);
  }

  //vendors  Api's
  getVendorList() {
    return this.http.get(`${this.apiUrl}vendor/list`);
  }

  getAllVendorList(data: any) {
    return this.http.post(`${this.apiUrl}vendor/list`, data);
  }

  updateVendor(vendorId: number, data: any) {
    return this.http.put(`${this.apiUrl}vendor/${vendorId}`, data);
  }

  createVendor(data: any) {
    return this.http.post(`${this.apiUrl}vendor`, data);
  }

  deleteVendor(storeId: number) {
    return this.http.delete(`${this.apiUrl}vendor/${storeId}`);
  }

  
  // add staff module api's
  getStaffList() {
    return this.http.get(`${this.apiUrl}staff/list`);
  }

  getAllStaffList(data: any) {
    return this.http.post(`${this.apiUrl}staff/list`, data);
  }

  deleteStaff(storeId: number){
    return this.http.delete(`${this.apiUrl}staff/${storeId}`);
  }

  createStaff(data: any) {
    return this.http.post(`${this.apiUrl}staff`, data);
  }

  updateStaff(staffId: number, data: any) {
    return this.http.put(`${this.apiUrl}staff/${staffId}`, data);
  }

  blockUnblockStaff(staffId: number, data: any){
    return this.http.post(`${this.apiUrl}staff/block-unblock/${staffId}`, data);
  }

  // Role api's
  getAllRoleList() {
    return this.http.get(`${this.apiUrl}role/list`);
  }

  getMainRoleList(data: any) {
    return this.http.post(`${this.apiUrl}role/list`, data);
  }

  getPageList() {
    return this.http.get(`${this.apiUrl}page/list`);
  }

  deleteRole(storeId: number){
    return this.http.delete(`${this.apiUrl}role/${storeId}`);
  }

  createRole(data: any) {
    return this.http.post(`${this.apiUrl}permission/assign`, data);
  }

  updateRole(id: number, data: any) {
    return this.http.put(`${this.apiUrl}permission/assign/${id}`, data);
  }

  getRoleById(roleId: number) {
    return this.http.get(`${this.apiUrl}role/${roleId}`);
  }

  // Setting 
  
  changePassword(data: any) {
    return this.http.post(`${this.apiUrl}change-password`, data);
  }
  
  updateProfile(data: any) {
    return this.http.post(`${this.apiUrl}update-profile`, data);
  }

  getOrgProfile() {
    return this.http.get(`${this.apiUrl}profile`)
  }
}
